import React from "react"
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import "./slider.css";

export default function BtnSlider({ direction, moveSlide }) {

    return (
        <button
            onClick={moveSlide}
            className={direction === "next" ? "btn-slide next" : "btn-slide prev"}
        >
            {direction === "next" ? <FaAngleRight color="#fff" size={36} /> : <FaAngleLeft color="#fff" size={36} />}
        </button>
    );
}